import React from "react";
import { Button } from "reactstrap";

const PageAlert = () => {
  return (
    <div className="new-tab">
      <div className="new-tab-box text-center">
        <p>EJAD Admin is open in another window. Click “Use Here” to use EJAD in this window.</p>
        <Button
          type="button"
          className="br10 shadows minh50 minw150"
          color="primary"
          onClick={() => {
            window.location.reload();
          }}
        >
          Use here
        </Button>
      </div>
    </div>
  );
};

export default PageAlert;
