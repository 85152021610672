/*
 * File: store.js
 * Author: Bhavin Prajapati
 * Created Date: November 1st, 2022
 * Last Modified Date: November 3rd, 2022
 * Description: This sets up a Redux store with a combined rootReducer and custom middleware configuration, allowing state management in the application.
 */

// ** Redux Imports
import rootReducer from "../reducer/rootReducer";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export { store };
