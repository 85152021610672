/*
 * File: navbar.js
 * Author: Bhavin Prajapati
 * Created Date: November 1st, 2022
 * Last Modified Date: November 3rd, 2022
 * Description: This Redux slice manages state related to bookmarks and search functionality. It includes asynchronous thunks for fetching bookmarks and updating bookmark status using Axios for API requests. The state includes search query, bookmarks, and suggestions, with reducers for handling search queries and extra reducers to update state based on asynchronous actions (e.g., fetching and updating bookmarks).
 */

// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getBookmarks = createAsyncThunk(
  "layout/getBookmarks",
  async () => {
    const response = await axios.get("/api/bookmarks/data");
    return {
      data: response.data.suggestions,
      bookmarks: response.data.bookmarks,
    };
  }
);

export const updateBookmarked = createAsyncThunk(
  "layout/updateBookmarked",
  async (id) => {
    await axios.post("/api/bookmarks/update", { id });
    return id;
  }
);

export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    query: "",
    bookmarks: [],
    suggestions: [],
  },
  reducers: {
    handleSearchQuery: (state, action) => {
      state.query = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookmarks.fulfilled, (state, action) => {
        state.suggestions = action.payload.data;
        state.bookmarks = action.payload.bookmarks;
      })
      .addCase(updateBookmarked.fulfilled, (state, action) => {
        let objectToUpdate;

        // ** find & update object
        state.suggestions.find((item) => {
          if (item.id === action.payload) {
            item.isBookmarked = !item.isBookmarked;
            objectToUpdate = item;
          }
        });

        // ** Get index to add or remove bookmark from array
        const bookmarkIndex = state.bookmarks.findIndex(
          (x) => x.id === action.payload
        );

        if (bookmarkIndex === -1) {
          state.bookmarks.push(objectToUpdate);
        } else {
          state.bookmarks.splice(bookmarkIndex, 1);
        }
      });
  },
});

export const { handleSearchQuery } = layoutSlice.actions;

export default layoutSlice.reducer;
