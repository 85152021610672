/*
 * File: rootReducer.js
 * Author: Bhavin Prajapati
 * Created Date: November 1st, 2022
 * Last Modified Date: January 15th, 2024 
 * Description: This rootReducer combines multiple reducers to manage different aspects of state in a Redux store efficiently.
 */

// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import orderTrackingFilterReducer from './orderTrackingFilterReducer'

const rootReducer = {
  auth,
  navbar,
  layout,
  orderTrackingFilterReducer
}

export default rootReducer
