/*
 * File: orderTrackingFilter.js
 * Author: Ankit Pithiya
 * Created Date: January 15th, 2024
 * Last Modified Date: January 16th, 2024
 * Description: This Redux slice manages state for order tracking filters, allowing dynamic setting and retrieval of filter parameters like name, type, and route origin status within a Redux store.
 */

import { createSlice } from "@reduxjs/toolkit";

export const orderTrackingFilterSlice = createSlice({
  name: "filterTrackingReducer",
  initialState: { name: "", type: "", isFromOtherRoute: false },
  reducers: {
    SET_ORDER_TRACKING_FILTERS: (state, action) => {
      state.name = action?.payload?.name;
      state.type = action?.payload?.type;
      state.isFromOtherRoute = action?.payload?.isFromOtherRoute;
    },
  },
});

export const { SET_ORDER_TRACKING_FILTERS } = orderTrackingFilterSlice.actions;

export default orderTrackingFilterSlice.reducer;
